
            @import 'src/styles/mixins.scss';
        
.animation {
    transition: all 0.3s ease-in-out;
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
.mainContainer {
    position: relative;
    margin-top: 90px;
    @include lg {
        margin-top: 185px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background-color: #eef2ff;
    }
}
